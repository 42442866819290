import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const t = TrelloPowerUp.iframe()
    const promise = $tpu.r.tasks.create(
      properties.formData,
      $tpu.r.lists.first().id,
      properties.scopeCardId,
      t)
    this.executeCardUpdate(promise, properties, component, true)
  }
}
